import _ from "underscore";
import Backbone from "backbone";
import pagination from "src/shared/pagination";
import Session from "js/Session";
import UserNotificationModel from "./UserNotificationModel";

const UserNotificationCollection = Backbone.Collection.extend(
    _.extend({}, pagination, {
        initialize(models, parameters) {
            this.filter = new Backbone.Model({
                was_snoozed: false,
            });
            this.userId = parameters.userId;
            pagination.initialize.call(this);
        },
        model: UserNotificationModel,
        url() {
            if (
                !Session.getInstance().isSuperUser() &&
                this.userId === Session.getInstance().getUserId()
            ) {
                return "api:///v6/notifications";
            }
            return `api:///v6/notifications/${this.userId}`;
        },
        fetch() {
            return Backbone.Collection.prototype.fetch.call(this, {
                data: {
                    was_snoozed: this.filter.get("was_snoozed"),
                },
            });
        },
        getUnreadNotificationCount(notificationCursorModel) {
            let unreadCount = 0;
            this.forEach((notificationModel) => {
                if (
                    notificationModel.isUnread(notificationCursorModel) &&
                    !notificationModel.wasSnoozed()
                ) {
                    unreadCount += 1;
                }
            });
            return unreadCount;
        },
    }),
    {
        create(userId) {
            return new UserNotificationCollection([], {
                userId,
            });
        },
    }
);

export default UserNotificationCollection;
