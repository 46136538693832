import _ from "underscore";
import Backbone from "backbone";
import Session from "js/Session";
import dateTimeService from "src/shared/date-time-service";

const UserNotificationSnoozeModel = Backbone.Model.extend(
    {
        initialize(attributes, parameters) {
            this.userId = parameters.userId;
        },
        idAttribute: "snooze_id",
        matches(userNotificationModel) {
            const havingExactlyTags = this.get("having_exactly_tags");
            const notificationTags = userNotificationModel.getReferenceTagSet();
            const sameLength =
                havingExactlyTags.length === notificationTags.length;
            const noDifferentValues = _.isEmpty(
                _.difference(havingExactlyTags, notificationTags)
            );
            return sameLength && noDifferentValues;
        },
        urlRoot() {
            const userId = this.userId || this.collection.userId;
            if (
                !Session.getInstance().isSuperUser() &&
                this.userId === Session.getInstance().getUserId()
            ) {
                return "api:///v6/notifications/snoozes";
            }
            return `api:///v6/notifications/${userId}/snoozes`;
        },
        getExpirationDateFormatted() {
            return dateTimeService.formatToDateTime(this.get("expires_at"));
        },
    },
    {
        create(userId, snoozeId) {
            return new UserNotificationSnoozeModel(
                {
                    snooze_id: snoozeId,
                },
                {
                    userId,
                }
            );
        },
    }
);

export default UserNotificationSnoozeModel;
