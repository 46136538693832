import Backbone from "backbone";
import _ from "underscore";
import $ from "jquery";
import Strings from "../Strings";

const MAP = {
    TANGO_V2: "Gift Cards",
    CUSTOM_REWARD: "Custom Rewards",
    MANUAL_COUPON: "Coupons",
    PAYPAL_PAYOUTS: "PayPal Payouts",
    SALESFORCE_COUPON: "SFCC Coupons",
};

const RewardSupplierDisplayTypesModel = Backbone.Model.extend({
    urlRoot: "/api/v6/reward-suppliers/display-types",

    parse(data) {
        return {
            value: data,
            urlValue: Strings.encodeLabel(data),
            label: MAP[data] || data,
        };
    },
});

const RewardSupplierDisplayTypesCollection = Backbone.Collection.extend({
    url: "/api/v6/reward-suppliers/display-types",
    model: RewardSupplierDisplayTypesModel,
});

RewardSupplierDisplayTypesCollection.create = function () {
    return new RewardSupplierDisplayTypesCollection();
};

export default RewardSupplierDisplayTypesCollection;
