import $ from "jquery";
import _ from "underscore";
import Session from "js/Session";
import Backbone from "backbone";
import dateTimeService from "src/shared/date-time-service";
import UserModel from "src/shared/users/UserModel";
import SubscriptionCollection from "src/shared/subscriptions/SubscriptionCollection";
import UserCollection from "src/shared/collections/UserCollection";
import UserNotificationCollection from "./UserNotificationCollection";
import NotificationCursorModel from "./NotificationCursorModel";
import UserNotificationSnoozeCollection from "./UserNotificationSnoozeCollection";
import UserNotificationSnoozeModel from "./UserNotificationSnoozeModel";

const NOTIFICATION_STATUS_UPDATE_TIMEOUT = 30 * 1000;

const loadUsersWithNotifications = _.memoize(() => {
    const deferred = $.Deferred();
    const subscriptionCollection = new SubscriptionCollection();
    const me = UserModel.create(Session.getInstance().getUserId());
    $.when(subscriptionCollection.fetch(), me.fetch())
        .done(() => {
            const users = _.flatten([
                me.toJSON(),
                subscriptionCollection
                    .toJSON()
                    .map((subscription) => subscription.user),
            ]);
            const subscriptionUsers = _.uniq(users, (user) => user.user_id);
            deferred.resolve(new UserCollection(subscriptionUsers));
        })
        .fail(deferred.reject);
    return deferred;
});

const NotificationStatusModel = Backbone.Model.extend({
    initialize() {
        this.cursor = getCursorInstance();
        this.cursor.on("change", this.fetch.bind(this));
        setInterval(this.fetch.bind(this), NOTIFICATION_STATUS_UPDATE_TIMEOUT);
    },
    defaults: {
        unread_notification_count: 0,
    },
    hasUnreadNotifications() {
        return this.get("unread_notification_count") > 0;
    },
    getUnreadNotificationCount() {
        return this.get("unread_notification_count");
    },
    markAsRead() {
        const now = dateTimeService.toDate();
        return this.cursor.read(now);
    },
    getCursor() {
        return this.cursor;
    },
    fetch() {
        const notifcations = UserNotificationCollection.create(
            Session.getInstance().getUserId()
        );
        $.when(notifcations.fetch(), this.cursor.fetch()).done(() => {
            this.set(
                "unread_notification_count",
                notifcations.getUnreadNotificationCount(this.cursor)
            );
        });
    },
});

const loadNotification = (userId, eventId) => {
    const deferred = $.Deferred();
    const userNotificationCollection =
        UserNotificationCollection.create(userId);
    $.when(userNotificationCollection.fetch()).done(() => {
        const notificationModel = userNotificationCollection.get(eventId);
        if (notificationModel) {
            deferred.resolve(notificationModel);
        } else {
            deferred.reject();
        }
    });
    return deferred;
};

const loadSnooze = (userId, snoozeId) => {
    const snoozeModel = UserNotificationSnoozeModel.create(userId, snoozeId);
    const deferred = $.Deferred();
    $.when(snoozeModel.fetch())
        .done(() => {
            deferred.resolve(snoozeModel);
        })
        .fail(deferred.reject);
    return deferred;
};

const getCursorInstance = _.memoize(() => new NotificationCursorModel());

const getStatusInstance = _.memoize(() => new NotificationStatusModel());

const getSnoozeCollectionInstance = _.memoize((userId) =>
    UserNotificationSnoozeCollection.create(userId)
);

export default {
    loadNotification,
    getCursorInstance,
    getStatusInstance,
    getSnoozeCollectionInstance,
    loadSnooze,
    loadUsersWithNotifications,
};
