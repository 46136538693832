import Backbone from "backbone";

const NotificationCursorModel = Backbone.Model.extend(
    {
        url() {
            return "api:///v6/notifications/read";
        },
        getLastReadEventTime() {
            return this.get("last_read_event_time");
        },
        read(time) {
            return this.save({
                last_read_event_time: time,
            });
        },
    },
    {
        create() {
            return new NotificationCursorModel();
        },
    }
);

export default NotificationCursorModel;
