import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

const SupportModel = Backbone.Model.extend({
    url: "/api/v4/support",
    isNew() {
        return false;
    },
});

export default SupportModel;
