import Backbone from "backbone";
import _ from "underscore";

export default _.extend(
    {
        EVENTS: {
            UPDATED: "UPDATED",
            INTEGRATION_CHANGED: "INTEGRATION_CHANGED",
        },
    },
    Backbone.Events
);
