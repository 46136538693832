import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Session from "Session";
import Foundation from "foundation";
import CampaignSummariesCollection from "src/shared/collections/CampaignSummariesCollection";
import RewardSupplierDisplayTypesCollection from "../collections/RewardSupplierDisplayTypesCollection";
import ClientSelector from "src/shared/navigation/ClientSelector";
import SecondaryNavigationView from "src/shared/navigation/SecondaryNavigationView";
import TooltipView from "src/shared/tooltip/TooltipView";
import Strings from "src/shared/Strings";
import MenuSchema from "src/shared/navigation/MenuSchema";
import SupportModel from "src/labs/support/SupportModel";
import promiseService from "src/shared/promise-service";
import NavigationEventBus from "src/shared/navigation/NavigationEventBus";
import NotificationFlashView from "src/shared/notification/NotificationFlashView";
import tooltipConfigurations from "src/shared/navigation/tooltip-configurations.json";
import headerLeftSideTemplate from "src/shared/navigation/left-side-template.html?raw";
import leftSideNavItemTemplate from "src/shared/navigation/left-side-nav-item-template.html?raw";
import clientSupportInfoTemplate from "src/shared/navigation/client-support-info-template.html?raw";
import NotificationBubbleView from "./NotificationBubbleView";
import CampaignType from "src/shared/campaigns/CampaignType";
import featureFlagService from "src/shared/feature-flag-service";

import headerLogoFullWidthUrl from "../../../assets/extole-logo.png";
import headerLogoCollapsedUrl from "../../../assets/extole-logomark.svg";

const SUPER_USER_PREFIX = /^su./;
const EXTOLE_NAVBAR_IS_COLLAPSED = "extole_navbar_is_collapsed";
const EXTOLE_SECONDARY_MENU_PARENT = "extole_secondary_menu_parent";
const EXTOLE_SUPPORT_INFO_IS_COLLAPSED = "extole_support_info_is_collapsed";

const COLLAPSED_CLASS = "collapsed";
const ANIMATION_TIME_IN_MILLISECONDS = 300;

const PROGRAMS_MENU = ["programs", "performance"];
const isShopify = (rewardSupplierDisplayType) => {
    return (rewardSupplierDisplayType.label || "")
        .toLowerCase()
        .includes("shopify");
};

const EVENTS = {
    SUPPORT_INFO_RENDERED: "support_info_rendered",
    SECONDARY_NAV_CLOSED: "secondary_nav_closed",
};

function getInitials(email) {
    return email.replace(SUPER_USER_PREFIX, "").substr(0, 1);
}

const UserModel = Backbone.Model.extend({
    idAttribute: "user_id",
    urlRoot: "/api/v2/users",
});

function loadClientContacts(csmUserId, supportEngineerUserId) {
    const csmUser = new UserModel({ user_id: csmUserId });
    const supportEngineerUser = new UserModel({
        user_id: supportEngineerUserId,
    });
    const result = $.Deferred();
    promiseService
        .promiseAll([
            csmUser.fetch.bind(csmUser),
            supportEngineerUser.fetch.bind(supportEngineerUser),
        ])
        .done(() => {
            result.resolve({
                clientSuccessManager: csmUser.has("email")
                    ? csmUser.toJSON()
                    : null,
                supportEngineer: supportEngineerUser.has("email")
                    ? supportEngineerUser.toJSON()
                    : null,
            });
        });
    return result;
}

const ClientSupportInfoView = Backbone.View.extend({
    template: _.template(clientSupportInfoTemplate),

    events: {
        "click .js-toggle-info": "toggleInfo",
    },

    initialize(options) {
        const self = this;
        this.csmModel = new UserModel({ user_id: options.csmUserId });
        this.supportModel = new UserModel({
            user_id: options.supportUserId,
        });
        this.slackChannel = options.slackChannel;
        this.externalSlackChannel = options.externalSlackChannel;
        this.eventBus = options.eventBus;
        this.isCollapsed =
            localStorage.getItem(EXTOLE_SUPPORT_INFO_IS_COLLAPSED) || "false";
        NotificationFlashView.createInside(this.$el);
        loadClientContacts(options.csmUserId, options.supportUserId).done(
            (contacts) => {
                self.render(contacts);
            }
        );
    },

    initializeState() {
        if (this.isCollapsed === "true") {
            this.$el.addClass(COLLAPSED_CLASS);
            this.$(".far").removeClass("fa-angle-down").addClass("fa-angle-up");
            $("#js-main-navigation").removeClass(
                "main-navigation--with-support-info"
            );
        } else {
            this.$el.removeClass(COLLAPSED_CLASS);
            this.$(".far").removeClass("fa-angle-up").addClass("fa-angle-down");
            $("#js-main-navigation").addClass(
                "main-navigation--with-support-info"
            );
        }
    },

    render(contacts) {
        const isSuperUser = Session.getInstance().isSuperUser();
        this.$el.html(
            this.template({
                clientSuccessManager: contacts.clientSuccessManager,
                supportEngineer: contacts.supportEngineer,
                slackChannel: this.slackChannel,
                externalSlackChannel: this.externalSlackChannel,
                isSuperUser,
            })
        );
        this.eventBus.trigger(EVENTS.SUPPORT_INFO_RENDERED);
        this.initializeState();
    },

    toggleInfo() {
        this.$el.toggleClass(COLLAPSED_CLASS);
        this.$(".far").toggleClass("fa-angle-down fa-angle-up");
        $("#js-main-navigation").toggleClass(
            "main-navigation--with-support-info"
        );
        const isCollapsed = this.$el.hasClass(COLLAPSED_CLASS);
        localStorage.setItem(EXTOLE_SUPPORT_INFO_IS_COLLAPSED, isCollapsed);
        this.eventBus.trigger(EVENTS.SUPPORT_INFO_TOGGLED, isCollapsed);
    },
});

ClientSupportInfoView.create = function ($el, eventBus) {
    const supportModel = new SupportModel();
    $.when(supportModel.fetch()).done((supportData) => {
        if (supportData.csm_user_id && supportData.support_user_id) {
            $el.siblings("#js-main-navigation").addClass(
                "main-navigation--with-support-info"
            );
            return new ClientSupportInfoView({
                el: $el,
                eventBus,
                csmUserId: supportData.csm_user_id,
                supportUserId: supportData.support_user_id,
                slackChannel: supportData.slack_channel_name,
                externalSlackChannel: supportData.external_slack_channel_name,
            });
        }
    });
};

const LeftSideNavMenuItem = Backbone.View.extend({
    tagName: "li",

    template: _.template(leftSideNavItemTemplate),

    events: {
        "click .js-toggle-secondary-nav": "toggleSecondaryMenu",
        "click .js-inner-menu": "setSecondaryMenuState",
    },

    initialize(options) {
        this.navItem = options.navItem;
        this.campaigns = options.campaigns;
        this.rewardSupplierDisplayTypes = options.rewardSupplierDisplayTypes;
        this.currentPage = options.currentPage;
        this.eventBus = options.eventBus;
        this.isUserSupportUser = options.isUserSupportUser;
        this.isCollapsed =
            localStorage.getItem(EXTOLE_NAVBAR_IS_COLLAPSED) || "false";
        this.secondaryMenuParent = localStorage.getItem(
            EXTOLE_SECONDARY_MENU_PARENT
        );
    },

    render() {
        if (this.campaigns) {
            const programMenuItems = _.chain(this.campaigns)
                .filter(
                    (campaign) =>
                        !_.some(
                            campaign.tags,
                            (tag) =>
                                tag === "hidden" ||
                                tag === "internal:configuration"
                        ) && campaign.campaign_type === CampaignType.MARKETING
                )
                .pluck("program_label")
                .uniq()
                .map((programLabel) => ({
                    text: programLabel,
                    link: `/program/#${programLabel}`,
                }))
                .value();
            this.navItem.secondaryNav = _.union(
                this.navItem.secondaryNav,
                programMenuItems
            );
        }
        const containsShopify = _.some(
            this.rewardSupplierDisplayTypes,
            isShopify
        );

        if (this.rewardSupplierDisplayTypes) {
            const integrationRewardSuppliers = _.chain(
                this.rewardSupplierDisplayTypes
            )
                .filter((rewardSupplierDisplayType) => {
                    if (
                        containsShopify &&
                        featureFlagService.isOn(
                            featureFlagService.FLAGS
                                .ENFORCE_SHOPIFY_RESTRICTIONS
                        )
                    ) {
                        return isShopify(rewardSupplierDisplayType);
                    }
                    return true;
                })
                .map((rewardSupplierDisplayType) => ({
                    text: Strings.capitalize(rewardSupplierDisplayType.value),
                    link: `/account-rewards/${rewardSupplierDisplayType.urlValue}`,
                    label: rewardSupplierDisplayType.label,
                }))
                .filter((rewardSupplierDisplayType) => {
                    return !_.contains(
                        _.pluck(this.navItem.secondaryNav, "text"),
                        rewardSupplierDisplayType.label
                    );
                })
                .value();

            this.navItem.secondaryNav =
                containsShopify &&
                featureFlagService.isOn(
                    featureFlagService.FLAGS.ENFORCE_SHOPIFY_RESTRICTIONS
                )
                    ? [
                          {
                              link: "/account-rewards",
                              text: "Rewards",
                          },
                          ...integrationRewardSuppliers,
                      ]
                    : _.union(
                          this.navItem.secondaryNav,
                          integrationRewardSuppliers
                      );
        }

        this.hasSecondaryNav =
            this.navItem.hasSecondaryNav &&
            !_.isEmpty(this.navItem.secondaryNav);
        this.showSecondaryNav =
            this.isUserSupportUser ||
            (this.currentPage === this.navItem.link &&
                this.isCollapsed === "false");
        this.isActive =
            this.currentPage === this.navItem.link ||
            this.secondaryMenuParent === this.navItem.name;
        this.$el.html(
            this.template(
                _.extend(this.navItem, {
                    currentPage: this.currentPage,
                    isCollapsed: this.isCollapsed,
                    secondaryMenuParent: this.secondaryMenuParent,
                    hasSecondaryNav: this.hasSecondaryNav,
                    isUserSupportUser: this.isUserSupportUser,
                    isActive: this.isActive,
                    showSecondaryNav: this.showSecondaryNav,
                    Strings,
                })
            )
        );
        this.$secondaryMenuWrapper = this.$(".js-inner-menu");
        this.renderSecondaryNavigation();
        return this;
    },

    renderSecondaryNavigation() {
        if (this.hasSecondaryNav) {
            new SecondaryNavigationView({
                el: this.$secondaryMenuWrapper,
                menuItems: this.navItem.secondaryNav,
                currentPage: this.currentPage,
            }).render();
        }
    },

    toggleSecondaryMenu(event) {
        event.preventDefault();
        const $target = $(event.currentTarget);
        this.eventBus.trigger(
            EVENTS.SECONDARY_NAV_CLOSED,
            $target.parents("li")
        );
        this.$secondaryMenuWrapper.toggle(ANIMATION_TIME_IN_MILLISECONDS);
        $(event.currentTarget)
            .find(".fa")
            .toggleClass("fa-chevron-right fa-chevron-down");
    },

    setSecondaryMenuState(event) {
        const $currentTarget = $(event.currentTarget);
        const parentLink = $currentTarget.data("parent");
        localStorage.setItem(EXTOLE_SECONDARY_MENU_PARENT, parentLink);
    },
});

const LeftSideNavView = Backbone.View.extend({
    events: {
        "click #js-header-change-dashboard-mode": "changeDashboardMode",
        "click #js-toggle-navbar": "toggleNavbar",
        "click .js-logout-button": "logOut",
    },

    template: _.template(headerLeftSideTemplate),

    className: "header-side__wrapper",

    initialize() {
        this.campaignSummariesCollection = new CampaignSummariesCollection();
        this.rewardSupplierDisplayTypesCollection =
            RewardSupplierDisplayTypesCollection.create();
        this.isCollapsed =
            localStorage.getItem(EXTOLE_NAVBAR_IS_COLLAPSED) || "false";
        this.supportModel = new SupportModel();
        this.eventBus = _.extend({}, Backbone.Events);
        this.eventBus.on(
            EVENTS.SECONDARY_NAV_CLOSED,
            this.closeAllSecondaryNav.bind(this)
        );
        this.eventBus.on(
            EVENTS.SUPPORT_INFO_TOGGLED,
            this.setNavigationHeight.bind(this)
        );
        NavigationEventBus.on(
            NavigationEventBus.EVENTS.UPDATED,
            this.render.bind(this)
        );
        NavigationEventBus.on(
            NavigationEventBus.EVENTS.INTEGRATION_CHANGED,
            () => {
                this.rewardSupplierDisplayTypesCollection
                    .fetch()
                    .done(this.render.bind(this));
            }
        );
        $.when(
            this.campaignSummariesCollection.fetch(),
            this.rewardSupplierDisplayTypesCollection.fetch()
        ).always(this.render.bind(this));
    },

    render() {
        const self = this;
        this.currentPage = Backbone.history.location.pathname;
        this.session = Session.getInstance();
        this.isSuperUser = this.session.isSuperUser();
        this.isUserSupportUser = this.session.isUserSupportUser();
        this.modeService = this.session.getModeService();
        this.clientCollection = this.session.getClients();
        this.hasClientSelector =
            this.isSuperUser || this.clientCollection.length > 1;
        const menuSchema = new MenuSchema();
        const menuItems = menuSchema.parseItems();
        const templateData = {
            hasClientSelector: this.hasClientSelector,
            isSuperUser: this.isSuperUser,
            isUserSupportUser: this.isUserSupportUser,
            isTestMode: this.modeService.isTestMode(),
            headerLogoFullWidthUrl,
            headerLogoCollapsedUrl,
        };
        this.$el.html(this.template(templateData));

        this.$mainNavWrapper = this.$("#js-main-navigation");

        _.each(menuItems, (navItem) => {
            self.addItem(navItem);
        });

        this.$secondaryMenuWrapper = this.$(".js-inner-menu-programs");
        this.$toggleNavbarIcon = this.$("#js-toggle-navbar").find(".fa");
        this.$toggleSecondaryNav = this.$(".js-toggle-secondary-nav");
        this.$innerMenuWrapper = this.$(".js-inner-menu");
        this.$supportInfoWrapper = this.$(".js-client-support-info");

        this.renderBottomSelectors();

        ClientSupportInfoView.create(this.$supportInfoWrapper, this.eventBus);
        NotificationBubbleView.renderInto(this.$(".js-notification-bubble"));

        this.initializeNavbarState();
        this.initializeSecondaryMenuState();

        $(document).foundation();
        TooltipView.renderAll(this.$el, tooltipConfigurations);

        return this;
    },

    addItem(navItem) {
        let item;
        if (_.contains(PROGRAMS_MENU, navItem.name)) {
            item = new LeftSideNavMenuItem({
                navItem,
                currentPage: this.currentPage,
                eventBus: this.eventBus,
                campaigns: this.campaignSummariesCollection.toJSON() || null,
            });
        } else {
            item = new LeftSideNavMenuItem({
                navItem,
                eventBus: this.eventBus,
                isUserSupportUser: this.isUserSupportUser,
                currentPage: this.currentPage,
                rewardSupplierDisplayTypes:
                    (navItem.name === "rewards" &&
                        this.rewardSupplierDisplayTypesCollection.toJSON()) ||
                    null,
            });
        }
        this.$mainNavWrapper.append(item.render().el);
    },

    toggleNavbar() {
        const self = this;
        if (this.$el.hasClass(COLLAPSED_CLASS)) {
            localStorage.setItem(EXTOLE_NAVBAR_IS_COLLAPSED, false);
            this.$toggleNavbarIcon.toggleClass(
                "fa-chevron-right fa-chevron-left"
            );
            this.$el.removeClass(COLLAPSED_CLASS);
            this.$el
                .find(".header-link-caret")
                .find(".fa")
                .removeClass("fa-chevron-down")
                .addClass("fa-chevron-right");
        } else {
            localStorage.setItem(EXTOLE_NAVBAR_IS_COLLAPSED, true);
            this.$toggleNavbarIcon.toggleClass(
                "fa-chevron-right fa-chevron-left"
            );
            this.$innerMenuWrapper.hide(ANIMATION_TIME_IN_MILLISECONDS, () => {
                self.$el.addClass(COLLAPSED_CLASS);
            });
        }
    },

    initializeNavbarState() {
        if (this.isCollapsed === "true") {
            this.$el.addClass(COLLAPSED_CLASS);
            this.$toggleNavbarIcon
                .addClass("fa-chevron-right")
                .removeClass("fa-chevron-left");
            this.$innerMenuWrapper.hide({
                duration: ANIMATION_TIME_IN_MILLISECONDS,
            });
        } else {
            this.$el.removeClass(COLLAPSED_CLASS);
            this.$toggleNavbarIcon
                .addClass("fa-chevron-left")
                .removeClass("fa-chevron-right");
        }
        this.accountContainerHeight = this.hasClientSelector ? 255 : 200;
        this.computedHeight = window.innerHeight - this.accountContainerHeight;
        this.$mainNavWrapper.height(this.computedHeight);
        this.eventBus.on(
            EVENTS.SUPPORT_INFO_RENDERED,
            this.setNavigationHeight.bind(this)
        );
        window.onresize = this.setNavigationHeight.bind(this, false);
    },

    setNavigationHeight(isCollapsed) {
        this.computedHeight = window.innerHeight - this.accountContainerHeight;
        if (
            isCollapsed ||
            localStorage.getItem(EXTOLE_SUPPORT_INFO_IS_COLLAPSED) === "true"
        ) {
            this.$mainNavWrapper.height(this.computedHeight);
        } else {
            this.$mainNavWrapper.height(
                this.computedHeight - this.$supportInfoWrapper.innerHeight()
            );
        }
    },

    initializeSecondaryMenuState() {
        const parent = localStorage.getItem(EXTOLE_SECONDARY_MENU_PARENT);
        this.$(`[data-parent='${parent}']`).show();
        localStorage.setItem(EXTOLE_SECONDARY_MENU_PARENT, "");
    },

    renderBottomSelectors() {
        const $userEmail = this.$(".js-user-email");
        const $userEmailDropdown = this.$("#js-user-email-dropdown");

        const $clientNameDropdown = this.$("#js-client-name-dropdown");
        const $userName = this.$("#js-user-name-dropdown");
        const $clientInitial = this.$(".js-user-initial-name");

        const email = this.session.getEmail();

        $userEmail.text(email);
        $userName.text(email);
        $clientInitial.text(getInitials(email));

        if (this.hasClientSelector) {
            const view = new ClientSelector({
                collection: this.clientCollection,
            });
            view.on(ClientSelector.EVENTS.OPENED, () => {
                Foundation.libs.dropdown.close($userEmailDropdown);
            });
            $clientNameDropdown.prepend(view.render().el);
        }
    },

    closeAllSecondaryNav($target) {
        const $targetInnerWrapper = $target.find(".js-inner-menu");
        const $targetToggleSecondaryNav = $target.find(
            ".js-toggle-secondary-nav"
        );
        this.$toggleSecondaryNav
            .not($targetToggleSecondaryNav)
            .find(".js-fa")
            .addClass("fa-chevron-right")
            .removeClass("fa-chevron-left");
        this.$innerMenuWrapper
            .not($targetInnerWrapper)
            .hide({ duration: ANIMATION_TIME_IN_MILLISECONDS });
    },

    changeDashboardMode(event) {
        event.preventDefault();
        this.modeService.setMode($(event.currentTarget).data("mode"));
    },

    logOut(event) {
        event.preventDefault();
        this.session.logout();
    },
});

LeftSideNavView.create = function () {
    return new LeftSideNavView().el;
};

export default LeftSideNavView;
