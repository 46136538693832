import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Session from "Session";
import featureFlagService from "src/shared/feature-flag-service";
import template from "src/shared/navigation/client-selector.html?raw";
import extoleLogoGrey from "assets/extole-logo-grey.png";
import "vendor/jquery.select2";

const EVENTS = {
    OPENED: "opened",
};

export default Backbone.View.extend(
    {
        tagName: "span",

        render() {
            const clients = _.clone(this.collection.toJSON()).sort(
                (client1, client2) => {
                    const name1 = client1.name.toLowerCase();
                    const name2 = client2.name.toLowerCase();
                    return name1.localeCompare(name2);
                }
            );
            const session = Session.getInstance();

            const data = {
                brandImageUrl:
                    session.ajaxPrefilter.processUrl("/api/v2/logo/image"),
                current_client_id: session.getClientId(),
                clients,
                extoleLogoGrey,
            };

            this.$el.html(_.template(template, data));

            const $dropdown = this.$el.find("select");
            const self = this;

            $dropdown.select2({
                width: "100%",
                dropdownCssClass: "header__select2-results",
                matcher(enteredText, clientName, $option) {
                    const clientId = $option.val();
                    const idMatch = new RegExp(enteredText, "i").test(clientId);
                    const nameMatch = new RegExp(enteredText, "i").test(
                        clientName
                    );
                    return nameMatch || idMatch;
                },
            });
            $dropdown.val(Session.getInstance().getClientId());

            $dropdown.on("select2-open", () => {
                self.trigger(EVENTS.OPENED);
            });

            $dropdown.on("change", (event) => {
                event.preventDefault();
                Session.getInstance().update({
                    client_id: $dropdown.val(),
                });
            });

            return this;
        },
    },
    {
        EVENTS,
    }
);
