import Backbone from "backbone";
import _ from "underscore";
import notificationService from "src/shared/user-notifications/user-notification-service";

const formatNotificationCount = (count) => (count > 99 ? 99 : count);

const NotificationBubbleView = Backbone.View.extend(
    {
        className: "notification-bubble",
        initialize() {
            this.notificationStatusModel =
                notificationService.getStatusInstance();
            this.notificationStatusModel.fetch();
            this.notificationStatusModel.on("change", this.render.bind(this));
            this.render();
        },
        events: {
            click: "redirectToNotificationsPage",
        },
        render() {
            const unreadNotificationCount =
                this.notificationStatusModel.getUnreadNotificationCount();
            if (unreadNotificationCount === 0) {
                this.$el.hide();
            } else {
                this.$el.text(formatNotificationCount(unreadNotificationCount));
                this.$el.fadeIn();
            }
        },
        redirectToNotificationsPage(event) {
            event.stopPropagation();
            document.location = "/notifications";
        },
    },
    {
        renderInto($el) {
            $el.append(new NotificationBubbleView().el);
        },
    }
);

export default NotificationBubbleView;
