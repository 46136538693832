import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Strings from "src/shared/Strings";
import template from "src/shared/navigation/secondary-navigation-template.html?raw";

const MAX_ITEMS_IN_DROPDOWN = 8;

const SecondaryNavigationView = Backbone.View.extend({
    template: _.template(template),

    events: {
        "click .js-show-more": "showMore",
        "click .js-show-less": "showLess",
    },

    initialize(options) {
        this.menuItems = options.menuItems;
        this.currentPage = options.currentPage;
    },

    render() {
        this.showLess();

        return this;
    },

    renderData(menuItems) {
        this.$el.html(
            this.template({
                menuItems,
                currentPage: this.currentPage,
                Strings,
            })
        );

        this.$showMoreLink = this.$(".js-show-more");
        this.$showLessLink = this.$(".js-show-less");
    },

    showMore() {
        this.renderData(this.menuItems);
        this.$showMoreLink.hide();
    },

    showLess() {
        const menuItems = this.menuItems.slice(0, MAX_ITEMS_IN_DROPDOWN);
        this.renderData(menuItems);
        this.$showLessLink.hide();

        if (this.menuItems.length <= MAX_ITEMS_IN_DROPDOWN) {
            this.$showMoreLink.hide();
        }
    },
});

export default SecondaryNavigationView;
