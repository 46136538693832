import Backbone from "backbone";
import moment from "moment";
import Session from "js/Session";
import linkifyHtml from "linkifyjs/html";
import Strings from "src/shared/Strings";
import dateTimeService from "src/shared/date-time-service";

const LEVEL_ICON_NAMES = {
    ERROR: "times-circle",
    WARN: "exclamation-triangle",
    INFO: "info-square",
};

const UserNotificationModel = Backbone.Model.extend(
    {
        initialize(attributes, parameters) {
            this.userId = parameters.userId;
        },
        idAttribute: "event_id",
        wasSnoozed() {
            return Boolean(this.get("snooze_id"));
        },
        urlRoot() {
            const userId = this.getUserId();
            if (
                !Session.getInstance().isSuperUser() &&
                this.userId === Session.getInstance().getUserId()
            ) {
                return "api:///v6/notifications";
            }
            return `api:///v6/notifications/${userId}`;
        },
        getReferenceTagSet() {
            return [this.get("name")].concat(this.get("tags"));
        },
        isUnread(notificationCursorModel) {
            const lastReadEventTime = moment(
                notificationCursorModel.getLastReadEventTime()
            );
            const eventTime = moment(this.get("event_time"));
            return lastReadEventTime.isBefore(eventTime) && !this.wasSnoozed();
        },
        getUserId() {
            return this.userId || this.collection.userId;
        },
        getDisplayName() {
            return Strings.capitalize(this.get("name"));
        },
        getLevelIconName() {
            const level = this.get("level");
            return LEVEL_ICON_NAMES[level];
        },
        getLevelClassIdentifier() {
            const level = this.get("level");
            return (level || "").toLowerCase();
        },
        getDisplayLevel() {
            return Strings.capitalize(this.get("level"));
        },
        getMessageHtml() {
            return linkifyHtml(this.get("message"), {
                target: {
                    url: "_blank",
                },
            });
        },
        getDateFormatted() {
            return dateTimeService.formatToDateTime(this.get("event_time"));
        },

        getDetailPageLink() {
            const eventId = this.get("event_id");
            const userId = this.getUserId();
            return `/notifications/view?event_id=${eventId}&user_id=${userId}`;
        },
    },
    {
        create(userId) {
            return new UserNotificationModel(
                {},
                {
                    userId,
                }
            );
        },
    }
);

export default UserNotificationModel;
