import Backbone from "backbone";
import Session from "js/Session";
import UserNotificationSnoozeModel from "./UserNotificationSnoozeModel";

const UserNotificationSnoozeCollection = Backbone.Collection.extend(
    {
        initialize(models, parameters) {
            this.userId = parameters.userId;
        },
        model: UserNotificationSnoozeModel,
        url() {
            if (
                !Session.getInstance().isSuperUser() &&
                this.userId === Session.getInstance().getUserId()
            ) {
                return "api:///v6/notifications/snoozes";
            }
            return `api:///v6/notifications/${this.userId}/snoozes`;
        },
    },
    {
        create(userId) {
            return new UserNotificationSnoozeCollection([], {
                userId,
            });
        },
    }
);

export default UserNotificationSnoozeCollection;
