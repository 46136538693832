import Session from "Session";
import _ from "lodash";
import featureFlagService from "src/shared/feature-flag-service";

function MenuSchema() {
    const isPaypalSupplierEnabled = featureFlagService.isOn(
        featureFlagService.FLAGS.PAYPAL_REWARD_SUPPLIER
    );
    const isSelfServiceEnabled = featureFlagService.isOn(
        featureFlagService.FLAGS.SELF_SERVICE_PAGE
    );
    const isLabsPageVisible = Session.getInstance().isSuperUser();
    const isUserSupportUser = Session.getInstance().isUserSupportUser();

    this.items = [
        {
            name: "programs",
            link: "/programs",
            icon: "<i class='fal fa-bookmark menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/programs",
                    text: "Programs",
                    isVisible: true,
                },
            ],
            isVisible: !isUserSupportUser,
        },

        {
            name: "performance",
            link: "/account",
            icon: "<i class='fal fa-signal menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/account",
                    text: "My Dashboard",
                    isVisible: true,
                },
            ],
            isVisible: !isUserSupportUser,
        },

        {
            name: "audiences",
            link: "/audiences-overview",
            icon: "<i class='fal fa-poll-people menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/audiences-overview",
                    text: "Audiences Overview",
                    isVisible: true,
                },
                {
                    link: "/audiences-overview/my-audiences",
                    text: "My Audiences",
                    isVisible: true,
                },
            ],
            isVisible: !isUserSupportUser,
        },

        {
            name: "rewards",
            link: "/account-rewards",
            icon: "<i class='fal fa-trophy menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/account-rewards",
                    text: "Rewards",
                    isVisible: true,
                },
                {
                    link: "/account-rewards/custom-rewards",
                    text: "Custom Rewards",
                    isVisible: true,
                },
                {
                    link: "/account-rewards/gift-cards",
                    text: "Gift Cards",
                    isVisible: true,
                },
                {
                    link: "/account-rewards/coupons",
                    text: "Coupons",
                    isVisible: true,
                },
                {
                    link: "/account-rewards/SFCC",
                    text: "SFCC Coupons",
                    isVisible: true,
                },
                {
                    link: "/account-rewards/paypal-payouts",
                    text: "Paypal Payouts",
                    isVisible: isPaypalSupplierEnabled,
                },
            ],
            isVisible: !isUserSupportUser,
        },
        {
            name: "reports",
            link: "/reports",
            icon: "<i class='fal fa-file-chart-line menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/reports#/history",
                    text: "History",
                    isVisible: true,
                },
                {
                    link: "/reports#/schedules",
                    text: "Schedules",
                    isVisible: true,
                },
            ],
            isVisible: !isUserSupportUser,
        },

        {
            name: "user-support",
            link: "/profiles/search",
            icon: "<i class='fal fa-user-headset menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/profiles/search",
                    text: "Person Search",
                    isVisible: true,
                },
                {
                    link: "/events/approvals",
                    text: "Approvals",
                    isVisible: true,
                },
                {
                    link: "/events/live",
                    text: "Event Live View",
                    isVisible: true,
                },
                {
                    link: "/create-event",
                    text: "Create Event",
                    isVisible: true,
                },
            ],
            isVisible: true,
        },

        {
            name: "partners",
            link: "/partners",
            icon: "<i class='fal fa-puzzle-piece menu-item__icon'></i>",
            hasSecondaryNav: false,
            secondaryNav: [],
            isVisible: !isUserSupportUser,
        },

        {
            name: "tech-center",
            link: "/tech-center",
            icon: "<i class='fal fa-brackets-curly menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/tech-center",
                    text: "Tech Center",
                    isVisible: true,
                },
                {
                    link: "/events/live",
                    text: "Event Live View",
                    isVisible: true,
                },
                {
                    link: "/tech-center/batch-jobs",
                    text: "Batch Jobs",
                    isVisible: true,
                },
                {
                    link: "/tech-center/tag-generator",
                    text: "Tag Generator",
                    isVisible: true,
                },
                {
                    link: "/tech-center/outbound-webhooks",
                    text: "Outbound Webhooks",
                    isVisible: true,
                },
            ],
            isVisible: !isUserSupportUser,
        },

        {
            name: "security-center",
            link: "/security-center",
            icon: "<i class='fal fa-shield-alt menu-item__icon'></i>",
            hasSecondaryNav: true,
            secondaryNav: [
                {
                    link: "/security-center",
                    text: "Security Center",
                    isVisible: true,
                },
                {
                    link: "/security-center/fraud-control",
                    text: "Quality Control",
                    isVisible: true,
                },
            ],
            isVisible: !isUserSupportUser,
        },

        {
            name: "labs",
            link: "/labs",
            icon: "<i class='fal fa-flask menu-item__icon'></i>",
            hasSecondaryNav: false,
            secondaryNav: [],
            isVisible: isLabsPageVisible,
        },

        {
            name: "Self Service",
            link: "/self-service",
            icon: "<i class='far fa-toolbox menu-item__icon'></i>",
            hasSecondaryNav: false,
            secondaryNav: [],
            isVisible: isSelfServiceEnabled && !isUserSupportUser,
        },
    ];

    this.parseItems = () => {
        return _.chain(this.items)
            .filter((item) => item.isVisible)
            .map((navItem) => {
                navItem.secondaryNav = _.filter(
                    navItem.secondaryNav,
                    (secondaryNavItem) => secondaryNavItem.isVisible
                );
                return navItem;
            })
            .value();
    };
}

export default MenuSchema;
